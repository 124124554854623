import React from "react"
import { Link } from "gatsby"
import { Themed, Badge, Box } from "theme-ui"
import Layout from "gatsby-theme-blog/src/components/layout"

const Tags = ({ pageContext: { tags }, location }) => {
  return (
    <Layout location={location} title="タグ一覧">
      <Themed.h1>タグ一覧</Themed.h1>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 3,
          my: 4
        }}
      >
        {tags.map(({ fieldValue: tag, totalCount }) => (
          <Link
            key={tag}
            to={`/tags/${tag}`}
            sx={{
              textDecoration: "none"
            }}
          >
            <Badge
              variant="tag"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                "&:hover": {
                  backgroundColor: "secondary",
                  transition: "background-color 0.2s"
                }
              }}
            >
              {tag}
              <Box
                as="span"
                sx={{
                  backgroundColor: "background",
                  color: "primary",
                  padding: "2px 8px",
                  borderRadius: "9999px",
                  fontSize: "0.75em"
                }}
              >
                {totalCount}
              </Box>
            </Badge>
          </Link>
        ))}
      </Box>
    </Layout>
  )
}

export default Tags
